import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loggedIn } from "./store/reducers/userReducer";
import "./App.scss";

import "react-toastify/dist/ReactToastify.css";
import MainRoute from "./routes/routes";
import "react-tippy/dist/tippy.css";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loggedIn());
  }, [dispatch]);

  return <MainRoute />;
};

export default App;
