import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loader from "../view/elements/Loader.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Component with Lazy Loading

const FinancierMRP = lazy(() =>
  import("../view/ph-calculator/FinancierMRP.js")
);
const Login = lazy(() => import("../view/login/Login.js"));
const CreditList = lazy(() => import("../view/credit-list/Credit-list.js"));
const NotFound = lazy(() => import("../view/not-found/NotFound"));
const showHeader = () => {
  return false;
};

const MainRoute = () => {
  const [logg, setLogg] = useState(false);
  const [isValidRoute, setIsValidRoute] = useState(true);

  const parentCallback = () => {
    let checklog = localStorage.getItem("loggedIn");
    if (checklog) setLogg(true);
  };

  const showHeader = () => {
    return setIsValidRoute(false);
  };
  return (
    <BrowserRouter>
      <div>
        {(logg && isValidRoute) || null}
        {/* <Navigation /> */}
        <ToastContainer />
        {/* <Suspense fallback={<Loader />}> */}
        <Routes>
          <Route
            exact
            path="/"
            element={<Login parentCallback={parentCallback} />}
          />

          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="*" element={<NotFound showHeader={showHeader} />} />
          <Route
            path={`/login`}
            element={<Login parentCallback={parentCallback} />}
          />
          <Route path={`/credit-list`} element={<CreditList />} />
          <Route path={`/dif-ph-calculator`} element={<FinancierMRP />} />
        </Routes>
        {/* </Suspense> */}
      </div>
    </BrowserRouter>
  );
};

export default MainRoute;
