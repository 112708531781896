import { createSlice } from "@reduxjs/toolkit";

const getUserInformationFromLocalStorage = () => {
  const userInformation = localStorage.getItem("user_information");
  if (userInformation) {
    try {
      return JSON.parse(userInformation);
    } catch (error) {
      console.error("Error parsing user information from localStorage:", error);
    }
  }
  return {};
};

export const userReducer = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    isLoggedOut: false,
    user_information: getUserInformationFromLocalStorage(),
  },
  reducers: {
    loggedIn: (state, action) => {
      state.isLoggedIn = Boolean(localStorage.getItem("loggedIn"));
      state.isLoggedOut = false;
      state.user_information = localStorage.getItem("user_information");

      //  localStorage.setItem('user_information', JSON.stringify(action.payload));
      //localStorage.setItem('user_information', JSON.stringify(action.payload));
    },
    loggedOut: (state) => {
      state.isLoggedIn = false;
      state.isLoggedOut = true;
      state.user_information = {};
      localStorage.removeItem("user_information");
    },
  },
});

export const checkUser = (state) => state.user.isLoggedIn;
export const checkLoggedOut = (state) => state.user.isLoggedOut;
export const getUserInformation = (state) => state.user.user_information;

export const { loggedIn, loggedOut } = userReducer.actions;

export default userReducer.reducer;
