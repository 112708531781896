import { configureStore } from "@reduxjs/toolkit";
import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from "./localStorage";
import userReducer from "./reducers/userReducer";

const persistedState = loadStateFromLocalStorage();

const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: persistedState,
});

window.addEventListener("beforeunload", () => {
  saveStateToLocalStorage(store.getState());
});

export default store;
